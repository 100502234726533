/* eslint-disable react/jsx-no-target-blank */
import { useAuth } from '../../../../app/modules/auth'
import { errorHandler, ID, isNotEmpty, selectUserRole, toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useQuery } from 'react-query'
import { getUserById } from '../../../partials/layout/header-menus/user-modal/core/_requests'
import { AxiosError } from 'axios'
import { User } from '@/app/models/user'

const SidebarFooter = () => {
  const { currentUser, logout } = useAuth()
  const enabledQuery: boolean = isNotEmpty(currentUser)

  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `user-${currentUser?.userId}`,
    () => {
      return getUserById(currentUser?.userId as ID)
    },
    {
      cacheTime: 0,
      enabled:enabledQuery,
      refetchOnWindowFocus: false,
      onError: (error) => {
        const err = error as AxiosError

        if(err?.response?.status === 401){
          logout();
        }
        if (err?.response) {
          const errorText = errorHandler(err?.response?.status,"");
          // setStatus(errorText)
        }
        console.error(err)
      },
    }
  )


  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      {/* <a
        href={process.env.REACT_APP_PREVIEW_URL}
        target='_blank'
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss-='click'
        title='Metronic Docs & Components'
      >
        <span className='btn-label'>Docs & Components</span>
        <KTSVG path='/media/icons/duotune/general/gen005.svg' className='btn-icon svg-icon-2 m-0' />
      </a> */}
      <div
        className='btn btn-flex flex-start btn-custom btn-primary overflow-hidden text-nowrap h-50px w-100 sidebar-footer-wrapper'
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss-='click'
      >
        <span className='btn-label'>

          <div
            className={'cursor-pointer symbol symbol-35px symbol-md-40px d-flex'}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <div className='symbol symbol-50px me-4'>
              <img alt='Logo' src={toAbsoluteUrl(`/media/sbc_assets/placeholder-image.png`)} />
            </div>
            <div className='d-flex flex-column justify-content-center'>
              <div className='fw-bolder d-flex align-items-center fs-7 text-start'>
                 {user?.firstName} {user?.lastName}
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-8 text-start'>
                {selectUserRole(user?.roleId as number)} 
              </a>
            </div>
          </div>
          <HeaderUserMenu user={user as User} isLoading={isLoading} error={error}/>
        </span>
      </div>
    </div>
  )
}

export { SidebarFooter }
