import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_ESL_API_URL

export const REGISTRATION_URL_TRAINER = `${API_URL}user/registration`

export const LOGIN_URL_TRAINER = `${API_URL}Authentication/Login`

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}Authentication/Login`
export const REGISTER_URL = `${API_URL}Authentication/Register`
export const REQUEST_PASSWORD_URL = `${API_URL}Authentication/ForgotPassword`
export const SET_PASSWORD_URL = `${API_URL}Authentication/RenewPassword`

export const INVITE_REGISTER = `${API_URL}user/invite-registration`


// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    EmailAddress: email,
    Password: password,
  })
}

// Server should return AuthModel
export function loginForTrainer(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL_TRAINER, {
    EmailAddress: email,
    Password: password,
  })
}


// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return AuthModel
export function registerRequest(
  email: string,
  password: string,
) {
  return axios.post(REGISTRATION_URL_TRAINER, {
    email,
    password,
    status:"inactive"
  }
  )
}

export function inviteRegister(
  token: string,
  email: string,
  password: string,
  last_name: string,
  first_name: string,
  phone: string,
  birth_date: string
) {
  return axios.post(INVITE_REGISTER, {
    invite_key: token,
    email: email,
    password: password,
    last_name: last_name,
    first_name: first_name,
    phone: phone,
    birth_date: birth_date
  }
  )
}


// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function setPassword(token:string,password: string) {
  return axios.patch<{ result: boolean }>(SET_PASSWORD_URL, {
  password_reset_token: token,
  password: password
  })
}
