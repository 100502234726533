import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import { AxiosError } from 'axios'
import { ErrorData, errorHandler } from '../../../../_metronic/helpers'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<boolean>(false)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting,resetForm }) => {
      try {
        setLoading(true)
        await requestPassword(values.email)
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
          resetForm()
        }, 2500)

      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a módosítás, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorInfo);
          setStatus(errorText)
          setTimeout(() => {
            setStatus("")
          }, 3000)
        }
      } finally {
        setSubmitting(true)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework forgotten-password-form'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Elfelejtette jelszavát ?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Adja meg az e-mail címét jelszava vissza állításához.
        </div>
        {/* end::Link */}
      </div>

      {formik.status && (
        <div className='alert alert-danger mt-4'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {success && (
        <div className='mb-10 bg-light-success p-4 rounded mt-4'>
          <div className='text-success'>Sikeres jelszó módosítási kérés! Kérem ellenőrizze az e-mail címét.</div>
        </div>
      )}

      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>E-mail</label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Elküld</span>
          {loading && (
            <span className='indicator-progress'>
              Kérem várjon...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Bezár
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
