import { FC, useCallback,useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ErrorData, errorHandler, GenderData, getBase64, toAbsoluteUrl } from '../../../../../../helpers'
import { User } from '@/app/models/user'
import clsx from 'clsx'
import { putUser } from '../../core/_requests'
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { AxiosError } from 'axios'
import { useQueryClient } from 'react-query'
import { UserListLoading } from '../../components/loading/UserListLoading'

type Props = {
  isUserLoading: boolean
  User: User
  cancel: () => void
}

const editUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 karakter a követelmény')
    .max(50, 'Maximum 50 karakter az engedélyezett')
    .required('Kötelező mező'),
  emailAddress: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 karakter a követelmény')
    .max(50, 'Maximum 50 karakter az engedélyezett')
    .required('Kötelező mező'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 karakter a követelmény.')
    .max(50, 'Maximum 50 karakter az engedélyezett')
    .required('Kötelező mező'),
  phoneNumber: Yup.string().required('Kötelező mező').nullable(),
})

const UserEditModalForm: FC<Props> = ({ User, isUserLoading,cancel }) => {
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [UserForEdit] = useState<User>({
    ...User,
  })

  const [fileList, setFileList] = useState([
    {
      uid: '-1',
    },
  ]);

  const queryClient = useQueryClient()

  const handleChange = useCallback((info: any) => {
    if (info.file.status === 'uploading') {
      info.file.status = 'done';
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl: any) => {
        const img = new Image();
        img.src = imageUrl;
        img.addEventListener('load', function () {
          formik.setFieldValue('image_url', imageUrl)
          formik.setFieldValue('image_deleted', 0)

          setFileList([{ uid: info.file.originFileObj.size }]);
        });
      });
    }
    if (info.file.status === 'removed') {
      setFileList([]);
      formik.setFieldValue('image_url', null)
      //Has deleted image
      formik.setFieldValue('image_deleted', 1)
    }

  }, []);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = getBase64(file.file.originFileObj, (imageUrl: any) => {
        const img = new Image();
        img.src = imageUrl;
        img.addEventListener('load', function () {

        });
      })
    }


    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const uploadButton = (
    <div className='upload-button'>
      <PlusOutlined className='plus-out' />
      <div
        style={{
          marginTop: 8
        }}
      >
        Feltöltés
      </div>
    </div>
  );


  const formik = useFormik({
    initialValues: UserForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setStatus, setSubmitting,resetForm }) => {
      setSubmitting(true)
      try {
        const user = Object.assign(UserForEdit, formik.values)
        const { data } = await putUser(user, user.userId)
    
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false)
          cancel()
          queryClient.invalidateQueries([`user-${user.userId}`])  
          resetForm()
        }, 2500)

      } catch (error) {
        const err = error as AxiosError<ErrorData>
        setStatus('Nem sikerült a módosítás, próbálja újra!')
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorInfo);
          setStatus(errorText)
          setTimeout(() => {
            setStatus("")
          }, 2500)
        }
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
         <form id='kt_modal_add_User_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column me-n7 pe-7'
        >

          <div className='fv-row mb-8'>
            <label className='fw-bold fs-4 my-2'>Elérhetőség:</label>
          </div>

          <div className='fv-row mb-7'>

            <>
              <Upload
                listType="picture-card"
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 2 ? null : uploadButton}
              </Upload>
              <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
                <img
                  alt={previewTitle}
                  className='mt-10'
                  style={{
                    width: '100%',
                  }}
                  src={previewImage}
                />
              </Modal>
            </>
            {/* end::Image input */}

            {/* begin::Hint */}
            {/* <div className='form-text'>Endgedélyezett típusok: png, jpg, jpeg.</div> */}
            {/* end::Hint */}
          </div>

          <div className='fv-row mb-8'>
            <div className='row'>
              <div className='col-lg-6'>
                <label className='fw-bold fs-6 mb-2 required'>E-mail</label>
                {/* end::Label */}
                {/* begin::Input */}
                <input
                  placeholder='E-mail'
                  {...formik.getFieldProps('email')}
                  type='text'
                  name='email'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    { 'is-invalid': formik.touched.emailAddress && formik.errors.emailAddress },
                    {
                      'is-valid': formik.touched.emailAddress && !formik.errors.emailAddress,
                    }
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.touched.emailAddress && formik.errors.emailAddress && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.emailAddress}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

      
        </div>
        {/* end::Scroll */}

        {formik.status && (
          <div className='alert alert-danger mt-6'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        {success && (
          <div className='mb-10 bg-light-success p-4 rounded mt-6'>
            <div className='text-success'>Sikeres profil módosítás!</div>
          </div>
        )}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading || success}
          >
            Vissza
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched || success}
          >
            <span className='indicator-label'>Mentés</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Kérem várjon...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UserListLoading />}
    </>
  )
}

export { UserEditModalForm }
