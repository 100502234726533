/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, FC } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { IUserModel } from '../core/_models'
import EyeButton from './eye-button/EyeButton'
import { sha256 } from 'crypto-hash';
import { AxiosError } from 'axios'
import { ErrorData, errorHandler } from '../../../../_metronic/helpers'
import { registerRequest } from '../core/_requests'

const registrationSchema = Yup.object().shape({
  acceptTerms: Yup.bool().required('Kötelező mező'),
  email: Yup.string()
    .email('Nem megfelelő formátum')
    .min(3, 'Minimum 3 karakter a követelmény')
    .max(50, 'Maximum 50 karakter az engedélyezett')
    .required('Kötelező mező'),
  password: Yup.string()
    .min(3, 'Minimum 3 karakter')
    .max(50, 'Maximum 50 karakter az engedélyezett')
    .required('Kötelező mező'),
  changepassword: Yup.string()
    .required('Kötelező mező')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Nem egyezik a két mező értéke"),
    }),
})

const initialValuesFilled = {
    id: 0,
    email: '',
    password: '',
    changepassword: '',
    acceptTerms: false,
}

const Registration: FC = () => {
  const [loading, setLoading] = useState(false)
  const [passwordType, setPasswordType] = useState("password");
  const [success, setSuccess] = useState<boolean>(false)

  const navigate = useNavigate();


  const formik = useFormik<IUserModel>({
    initialValues: initialValuesFilled as IUserModel,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
        setLoading(true)
        try {
          const sha256Pw = await sha256(values.password);
          const { data: auth } = await registerRequest(
            values.email,
            sha256Pw,
          )
  
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false)
            resetForm()
            navigate('/auth/successful-registration')
          }, 2500)
  
        } catch (error) {
          const err = error as AxiosError<ErrorData>
          setStatus('Nem sikerült a módosítás, próbálja újra!')
          if (err.response) {
            const errorText = errorHandler(err.response.status, err.response.data.errorInfo);
            setStatus(errorText)
            setTimeout(() => {
              setStatus("")
            }, 2500)
          }
        } finally {
          setSubmitting(true)
          setLoading(false)
        }
  
      },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-8'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Regisztráció</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}


      {/* begin::Form group Email */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6 required'>E-mail</label>
        <input
          placeholder='E-mail'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-4' data-kt-password-meter='true'>
        <div className='mb-1 position-relative'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Jelszó</label>
          <div className='mb-3'>
            <div className='position-relative'>
              <input
                type={passwordType}
                placeholder='Jelszó'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <EyeButton passwordType={passwordType} setPasswordType={setPasswordType} password={formik.values.password}></EyeButton>
            </div>

            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        {/* <div className='text-muted'>
        Használjon 8 vagy több karaktert betűk, számok és szimbólumok keverékével.
        </div> */}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6 required'>Jelszó újra</label>
        <input
          type='password'
          placeholder='Jelszó újra'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}


      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <label className='form-check form-check-inline required' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            checked={formik.values.acceptTerms}
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            Elfogadom az{' '}
            <Link
              to='/dokumentumok/aszf'
              target='_blank'
              className='ms-1 link-primary'
            >
              Általános szerződési feltételeket
            </Link>
            {' '}és az{' '}<Link
              to='/dokumentumok/adatvedelmi-iranyelvek'
              target='_blank'
              className='ms-1 link-primary'
            >
              Adatvédelmi nyilatkozatot
            </Link>.
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {formik.status && (
        <div className='alert alert-danger mt-4'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {success && (
        <div className='mb-10 bg-light-success p-4 rounded mt-4'>
          <div className='text-success'>Sikeres regisztráció!</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Tovább</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Kérem várjon..{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <div>
          Már van fiókja? {' '} <Link to='/auth/login' className='link-primary fw-bolder'>Bejelentkezés</Link>
        </div>
      </div>
      {/* end::Form group */}
    </form>
  )
}

export default Registration;
