/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { ErrorData, errorHandler } from '../../../../_metronic/helpers'
import { AxiosError } from 'axios'
import { useAuth } from '../../auth/core/Auth'
import ReactCodeInput from 'react-code-input'
import { useNavigate } from "react-router-dom";

export function Join() {
  const navigate = useNavigate();

  const { saveTraineeAuth, setTrainee } = useAuth()

  const [loading, setLoading] = useState(false)
  const [ code, setCode ] = useState('')

  const handleSubmit = async (e: any) => {
      e.preventDefault();
      setLoading(true)
      try {
        /*const { data: auth } = await traineeJoin(values.code);*/

        const mockAuth = {
          id: 1,
          name: '',
          accessToken: 'mock-token',
          group: {
              id: 1,
              name: 'Test Group',
              status: 'pending',
              createdAt: '2021-09-01T12:00:00Z',
              updatedAt: '2021-09-01T12:00:00Z',
          }
      }

        saveTraineeAuth(mockAuth)
        setTrainee(mockAuth)
        navigate('/trainee/set-name');
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorInfo);
        }
      } finally {
        setLoading(false)
      }
  }

  return (
    <form
      className='form w-100 text-center'
      onSubmit={handleSubmit}
      noValidate
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='fw-bolder mb-5 text-uppercase text-white' style={{fontSize: 32}}>Csoport neve</h1>
      </div>
      {/* begin::Heading */}

      <p className="fs-2 mb-20">Csatlakozás csoporthoz</p>

      {/* begin::Form group */}
      <div className='fv-row mb-20'>
        <label className='form-label fs-5 text-white d-block text-center'>Csoportkód</label>
        <ReactCodeInput value={code} onChange={(value) => setCode(value)}  name='code' inputMode='numeric' type='text' fields={4} />
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-20'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-esl-red btn-shadow btn-secondary'
          disabled={code?.length < 4 || loading}
        >
          {!loading && <span className='indicator-label'>Tovább</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Kérem várjon...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
