import clsx from "clsx"
import { Dispatch, FC, SetStateAction } from "react"

type Props={
password:string | undefined,
passwordType: string | undefined,
setPasswordType: Dispatch<SetStateAction<string>>
}


const EyeButton : FC<Props> = ({password,passwordType,setPasswordType}) => {

    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
      }
    
return(
    <div className={clsx('input-group-btn eye-btn-wrapper',{'d-none':!password})}>
      <button className="btn" onClick={togglePassword} type="button">
        {passwordType === "password" ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
      </button>
    </div>
)

}

export default EyeButton;