/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const TraineeLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])


  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column w-lg-450px'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid h-100'>
          {/* begin::Wrapper */}
          <div className='trainee-sidebar'>
            <div className="trainee-sidebar__header">
              <img alt='ESLCard' src={toAbsoluteUrl('/media/esl/logo.png')} className='h-75px' />
            </div>
            <div className='trainee-sidebar__content'>
              <Outlet />
            </div>
            
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-none d-lg-flex flex-lg-row-fluid w-lg description-with-image--gray'
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100 position-relative'>
          
          {/* begin::Image */}
          <img
            className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/esl/logo.png')}
            alt='ESLCard'
          />
          {/* end::Image*/}

        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export { TraineeLayout }
