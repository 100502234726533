import {Navigate, Route, Routes} from 'react-router-dom'
import {Join} from './components/Join'
import {TraineeLayout} from './TraineeLayout'
import { useAuth } from '../auth'
import { SetName } from './components/SetName'
import { Lobby } from './components/Lobby'
import { Group } from './components/Group'

const TraineePage = () => {
  const { trainee } = useAuth()
  return (
    <Routes>
      <Route element={<TraineeLayout />}>
        { trainee ? (
          <>
            <Route path='set-name' element={<SetName />} />
            <Route path='lobby' element={<Lobby />} />
            <Route path='*' element={<Navigate to='/trainee/lobby' />} />
            <Route index element={<Navigate to='/trainee/lobby' />} />
          </>
        ) : (
          <>
            <Route path='join' element={<Join />} />
            <Route path='*' element={<Navigate to='/trainee/join' />} />
            <Route index element={<Navigate to='/trainee/join' />} />
          </>
        )}
      </Route>
      <Route path='group' element={<Group />} />
    </Routes>
  )
}

export {TraineePage}
