/* eslint-disable jsx-a11y/anchor-is-valid */
import {useCallback, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../auth'

/**
 * CardDTO{
cardId	integer($int32)
name	string
nullable: true
content	string
nullable: true
dC_CardTypeId	integer($int32)
dC_CardType	DC_CardType{...}
cardPackageId	integer($int32)
cardPackage	CardPackage{...}
}
 */

type CardDTO = {
  cardId: number
  name?: string
  content?: string
  altContent?: string
  dC_CardTypeId: number
  dC_CardType: any
  cardPackageId: number
  cardPackage: any
  isFlipped?: boolean
}

const Group = () => {
  const navigate = useNavigate()
  const {saveTraineeAuth, setTrainee, trainee} = useAuth()
  const [usersOpened, setUsersOpened] = useState(false)

  const [ cards, setCards ] = useState<CardDTO[]>([])
  const [ card, setCard ] = useState<CardDTO | undefined>(undefined)
  const [ isReShuffle, setIsReShuffle ] = useState(false);

  const loadCards = async () => {
    // TODO - fetch cards from the server
    // mock data
    const cards: CardDTO[] = [
        {
          cardId: 1,
          name: 'Card 1',
          content: 'Card 1 content',
          altContent: 'Card 1 alt content',
          dC_CardTypeId: 1,
          dC_CardType: {},
          cardPackageId: 1,
          cardPackage: {}
        },
        {
          cardId: 2,
          name: 'Card 2',
          content: 'Card 2 content',
          dC_CardTypeId: 2,
          dC_CardType: {},
          cardPackageId: 2,
          cardPackage: {}
        }
      ];
    setCards(cards);
    setCard({...cards[0], isFlipped: false});
  }


  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    loadCards();
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  const handleHomeClick = () => {
    // TODO - confirm popup!
    // If confirm, remove traineeAuth and trainee, then navigate to /trainee/join
    saveTraineeAuth(undefined)
    setTrainee(undefined)
    navigate('/trainee/join');
  }

  const handleCardNextClick = () => {
    const index = cards.indexOf(card!)
    setCard(cards[index + 1])

    if(!cards[index + 1]) {
      setTimeout(() => {
        setIsReShuffle(true);
        setTimeout(() => {
          setIsReShuffle(false);
          setCard(cards[0]);
        }, 3000);
      }, 5000);
    }
  }

  const handleCardPrevClick = () => {
    const index = cards.indexOf(card!)
    setCard(cards[index - 1]);
  }

  const handleCardFlipClick = () => {
    if(card) {
      card.isFlipped = !card?.isFlipped;
    }
  }

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-none d-lg-flex flex-column w-lg-450px'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid h-100'>
          {/* begin::Wrapper */}
          <div className='trainee-sidebar'>
            <div className='trainee-sidebar__header'>
              <img
                alt='ESLCard'
                src={toAbsoluteUrl('/media/esl/logo.png')}
                className='h-75px'
              />
            </div>
            <div className='trainee-sidebar__content'>
            <div className='text-center mb-11'>
              <h1 className='fw-bolder mb-5 text-uppercase text-white' style={{fontSize: 32}}>
                {trainee?.group?.name}
              </h1>
            </div>
              <div className='esl-accordion'>
                <div className={`esl-accordion__item ${usersOpened ? 'opened' : ''}`}>
                  <div
                    className='esl-accordion__header'
                    onClick={() => setUsersOpened(!usersOpened)}
                  >
                    <div className='esl-accordion__arrow'>
                      <i className='bi bi-chevron-down'></i>
                    </div>
                    <div className='esl-accordion__header-content'>8 felhasználó csatlakozott</div>
                  </div>
                  <div className={`esl-accordion__body`}>
                    <ul className='trainee-group-list'>
                      <li className='trainee-group-list__item'>John doe</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className='d-grid mb-20'>
                <button
                  type='button'
                  onClick={() => handleHomeClick()}
                  className='btn btn-esl-red btn-shadow btn-secondary'
                >
                  <span className='indicator-label'>Vissza a csoport csatlakozáshoz</span>
                </button>
              </div>
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <main
        className='trainee-content'
      >
        {/* begin::Content */}
        <div className="trainee-content__header">
          <div className="row align-items-center mb-5">
            <div className="col">
              <h1 className='h3 mb-0 text-white' style={{fontSize: 18}}>
                    {trainee?.group?.name}
              </h1>
            </div>
            <div className="col-auto">
              <button className="btn" onClick={() => handleHomeClick()}>
                <KTSVG path='/media/esl/icons/logout.svg' className='svg-icon-2' />
              </button>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col">
              <div>8 felhasználó </div>
            </div>
            <div className="col-auto">
              02:32
            </div>
          </div>
        </div>
        <div className='trainee-content__body'>
          { isReShuffle && (
          <div className='trainee-card trainee-card--reshuffle'>
            <img src="/media/esl/ujrakeveres.gif" alt="Újrakeverés" />
          </div>
          )}
          { !isReShuffle && card && (
            <>
              <div className='trainee-card'>
                <div className='trainee-card__header'>
                  <div className='trainee-card__header-title'>
                    {card.name}
                  </div>
                </div>
                <div className='trainee-card__content' dangerouslySetInnerHTML={{ __html: card.content ?? '' }}></div>
              </div>
            </>
          )}
          { !isReShuffle && !card && (
          <div className='trainee-card trainee-card--no-card'>
              <img src="/media/esl/snail.gif" alt="Nincs több kártya" />
              <h3 className="mb-0 mt-5">SNAIL</h3>
          </div>
          )}
        </div>
        <div className="trainee-content__footer">
          { isReShuffle && (
            <>Újrakeverés - Kérjük, várj!</>
          )}
          { !isReShuffle && !card && (
            <>Elfogytak a kártyák</>
          )}
          { !isReShuffle && card && (
            <>
              <button
                type='button'
                className='btn p-3'
                onClick={() => handleCardPrevClick()}
                disabled={card.cardId === cards[0].cardId}
              >
                <KTSVG path='/media/esl/icons/card-next.svg' className='svg-icon--trainee-card-action' svgClassName="rotate-180" />
              </button>
              { card?.dC_CardTypeId === 1 && (
              <button
                type='button'
                className='btn p-3 d-inline-block d-lg-none'
                onClick={() => handleCardFlipClick()}
              >
                <KTSVG path='/media/esl/icons/card-turn.svg' className='svg-icon--trainee-card-action' svgClassName="" />
              </button>
              )}
              <button
                type='button'
                className='btn p-3'
                onClick={() => handleCardNextClick()}
              >
                <KTSVG path='/media/esl/icons/card-next.svg' className='svg-icon--trainee-card-action' svgClassName="" />
              </button>
            </>
          )}
        </div>
        {/* end::Content */}
      </main>
      {/* end::Aside */}
    </div>
  )
}

export {Group}
