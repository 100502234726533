import axios, { AxiosResponse } from 'axios'
import { ID} from '../../../../../helpers'
import { User, UserQueryResponse } from '@/app/models/user'

const API_URL = process.env.REACT_APP_ESL_API_URL

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .post(`${API_URL}Authentication/GetUser`, {userId: id})
    .then((response: AxiosResponse<UserQueryResponse>) => response.data.data)
}

const createOrUpdateUser = (user: User, user_id: ID) => {
  const url = user_id ? `${API_URL}Authentication/UpdateUser` : `${API_URL}Authentication/CreateUser`
  return axios.post<User>(url, {
    userId: user_id,
    userName: user.userName,
    firstName: user.firstName,
    lastName: user.lastName,
    genderId: user.genderId,
    emailAddress: user.emailAddress,
    phoneNumber: user.phoneNumber,
    postalCode: user.postalCode,
    dateOfBirth: user.dateOfBirth,
    aszfAccepted: user.aszfAccepted,
    marketingStatementAccepted: user.marketingStatementAccepted,
    isAdult: user.isAdult,
    isActive: user.isActive,
    roleId: user.roleId
  })
}

export { getUserById, createOrUpdateUser as putUser }

