import { AuthModel } from './_models'

const TRAINEE_AUTH_LOCAL_STORAGE_KEY = 'trainee-auth-react-v'
const SBC_LOCAL_STORAGE_KEY = 'sbc-auth-react-v'

const getAuthSbc = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(SBC_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also


      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuthSbc = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)

    localStorage.setItem(SBC_LOCAL_STORAGE_KEY, lsValue)

  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}


const removeAuthSbc = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(SBC_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getTraineeAuth = (): any | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(TRAINEE_AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: any = JSON.parse(lsValue) as any
    if (auth) {
      // You can easily check auth_token expiration also

      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setTraineeAuth = (auth: any) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)

    localStorage.setItem(TRAINEE_AUTH_LOCAL_STORAGE_KEY, lsValue)

  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeTraineeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(TRAINEE_AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}


export function initAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      config.headers.Authorization = `Basic ${process.env.REACT_APP_SBC_AUTH_TOKEN}`
      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.request.use(
    (config: any) => {
      const authSbc = getAuthSbc()
      config.headers['User-Auth'] = `${authSbc?.token}`;
      return config;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );

}



export { getAuthSbc as getauth, setAuthSbc as setAuth, removeAuthSbc as removeauth, TRAINEE_AUTH_LOCAL_STORAGE_KEY as AUTH_LOCAL_STORAGE_KEY, SBC_LOCAL_STORAGE_KEY, getTraineeAuth, setTraineeAuth, removeTraineeAuth}
