/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import preval from 'preval.macro'
import React from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../helpers'
import packageJson from '../../../../../package.json';

const HelpDrawer = () => {
  return (
    <div
      id='kt_help'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='help'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_help_toggle'
      data-kt-drawer-close='#kt_help_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100'>
        {/* begin::Header */}
        <div className='card-header' id='kt_help_header'>
          <div className='card-title-wrapper d-flex justify-content-center align-items-start flex-column ps-6'>
            <h5 className='fw-bold text-gray-600 text-align-left'>Aktuális verziószám: <small className="text-muted">{packageJson.version}</small></h5>
            <h5 className='fw-bold text-gray-600 text-align-left'>Frissítve: <small className="text-muted">{preval`module.exports = new Date().toLocaleString();`}</small></h5>
          </div>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_help_close'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body' id='kt_help_body'>
          {/* begin::Content */}
          <div
            id='kt_help_scroll'
            className='hover-scroll-overlay-y'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_help_body'
            data-kt-scroll-dependencies='#kt_help_header'
            data-kt-scroll-offset='5px'
          >
            {/* begin::Support */}
            <div className='rounded border border-dashed border-gray-300 p-6 p-lg-8 mb-10'>
              {/* begin::Heading */}
              {/* <h2 className='fw-bolder mb-5'>
                Support at{' '}
                <a href='#' className=''>
                  devs.keenthemes.com
                </a>
              </h2> */}
              {/* end::Heading */}

              {/* begin::Description */}
              <div className='fs-5 fw-bold mb-5'>
                <span className='text-gray-500'>
                  Bármilyen kérdés esetén a következő csatornán vegye fel velünk a kapcsolatot.
                </span>
              </div>
              {/* end::Description */}

              {/* begin::Link */}
              <a
                href='mailto:info@sbcloud.hu'
                className='btn btn-lg explore-btn-primary w-100'
              >
                Kapcsolatfelvétel
              </a>

              {/* Current Build Date */}
              {/* Build Date: {preval`module.exports = new Date().toLocaleString();`}. */}

              {/* end::Link */}
            </div>
            {/* end::Support */}

            {/* begin::Link */}
            <div className='d-flex align-items-center mb-7'>
              {/* begin::Icon */}
              <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-warning'>
                <KTSVG
                  path='/media/icons/duotune/abstract/abs033.svg'
                  className='svg-icon-warning svg-icon-2x svg-icon-lg-3x'
                />
              </div>
              {/* end::Icon */}
              {/* begin::Info */}
              <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                {/* begin::Wrapper */}
                <div className='d-flex flex-column me-2 me-lg-5'>
                  {/* begin::Title */}
                  <Link
                    to='/dokumentumok/aszf'
                    className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'
                  >
                    Általános Szerződési Feltételek
                  </Link>
                  {/* end::Title */}
                  {/* begin::Description */}
                  <div className='text-muted fw-bold fs-7 fs-lg-6'>
                    Tartalmazza a Szolgáltató és a Felhasználó között létrejövő
                    Szerződés általános szerződési feltételeit.
                  </div>
                  {/* end::Description */}
                </div>
                {/* end::Wrapper */}
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-gray-400 svg-icon-2'
                />
              </div>
              {/* end::Info */}
            </div>
            {/* end::Link */}
            {/* begin::Link */}
            <div className='d-flex align-items-center mb-7'>
              {/* begin::Icon */}
              <div className='d-flex flex-center w-50px h-50px w-lg-75px h-lg-75px flex-shrink-0 rounded bg-light-primary'>
                <KTSVG
                  path='/media/icons/duotune/abstract/abs027.svg'
                  className='svg-icon-primary svg-icon-2x svg-icon-lg-3x'
                />
              </div>
              {/* end::Icon */}
              {/* begin::Info */}
              <div className='d-flex flex-stack flex-grow-1 ms-4 ms-lg-6'>
                {/* begin::Wrapper */}
                <div className='d-flex flex-column me-2 me-lg-5'>
                  {/* begin::Title */}
                  <Link
                                to='/dokumentumok/adatvedelmi-iranyelvek'
                    className='text-dark text-hover-primary fw-bolder fs-6 fs-lg-4 mb-1'
                  >
                    Adatvédelmi Irányelvek
                  </Link>
                  {/* end::Title */}
                  {/* begin::Description */}
                  <div className='text-muted fw-bold fs-7 fs-lg-6'>
                    A jelen Adatvédelmi irányelvek tájékoztatást adnak arról, hogy milyen adatokat gyűjtünk, illetve Ön hogyan kezelheti, adatait.
                  </div>
                  {/* end::Description */}
                </div>
                {/* end::Wrapper */}
                <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-gray-400 svg-icon-2'
                />
              </div>
              {/* end::Info */}
            </div>
            {/* end::Link */}


          </div>
          {/* end::Content */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Card */}
    </div>
  )
}

export { HelpDrawer }
