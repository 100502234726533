import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import {  AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { WithChildren } from '../../../../_metronic/helpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setcurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  currentUserCan: (permission: string) => boolean
  traineeAuth: AuthModel | undefined
  saveTraineeAuth: (auth: any | undefined) => void
  trainee: any | undefined
  setTrainee: Dispatch<SetStateAction<any | undefined>>
  traineeLogout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getauth(),
  saveAuth: () => { },
  currentUser: undefined,
  setcurrentUser: () => { },
  logout: () => { },
  currentUserCan: () => false,
  traineeAuth: authHelper.getTraineeAuth(),
  saveTraineeAuth: () => { },
  trainee: undefined,
  setTrainee: () => { },
  traineeLogout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const[auth, setAuth] = useState<AuthModel | undefined>(authHelper.getauth())
  const [currentUser, setcurrentUser] = useState<UserModel | undefined>()

  const [traineeAuth, setTraineeAuth] = useState<any>(authHelper.getTraineeAuth()) // TODO: type
  const [trainee, setTrainee] = useState<any>(authHelper.getTraineeAuth()) // TODO: type

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth.data)
    } else {
      authHelper.removeauth()
    }
  }

  const saveTraineeAuth = (auth: any | undefined) => {
    setTraineeAuth(auth)
    if (auth) {
      authHelper.setTraineeAuth(auth)
    } else {
      authHelper.removeTraineeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setcurrentUser(undefined)
  }

  const traineeLogout = () => {
    saveTraineeAuth(undefined)
    setTrainee(undefined)
  }

  const currentUserCan = (permission: string) => { // TODO
    return true;
    // return currentUser?.roleXRight?.includes(permission) || false
  }

  return (
    <AuthContext.Provider value={{ auth,  saveAuth,  currentUser, setcurrentUser, logout, currentUserCan, trainee, setTrainee, traineeAuth, saveTraineeAuth, traineeLogout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth,  setcurrentUser, logout } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (user: AuthModel) => {
      try {
        if (!didRequest.current) {
     

          setcurrentUser(user as any)

        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.token) {
      requestUser(auth)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])



  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
