/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {ErrorData, errorHandler} from '../../../../_metronic/helpers'
import {AxiosError} from 'axios'
import {useAuth} from '../../auth/core/Auth'
import {useNavigate} from 'react-router-dom'

export function Lobby() {
  const navigate = useNavigate()
  const {saveTraineeAuth, setTrainee, trainee} = useAuth()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchGroupStatus = async () => {
      try {
        // TODO
        //const { data: group } = await getGroupStatus(trainee.group.id);
        const group = {
          id: 1,
          name: 'Test Group',
          status: Math.random() < 0.5 ? 'pending' : 'active',
          createdAt: '2021-09-01T12:00:00Z',
          updatedAt: '2021-09-01T12:00:00Z',
        }

        if (group.status === 'active') {
          setLoading(true)
          clearInterval(fetchStatusIntervalId!)

          setTimeout(() => {
            navigate('/trainee/group')
          }, 5000)
        }

        const updatedTrainee = {...trainee, group}
        setTrainee(updatedTrainee)
        saveTraineeAuth(updatedTrainee)
      } catch (error) {
        const err = error as AxiosError<ErrorData>
        if (err.response) {
          const errorText = errorHandler(err.response.status, err.response.data.errorInfo)
          console.warn(errorText)
        }
      }
    }

    const fetchStatusIntervalId = setInterval(fetchGroupStatus, 5000)
    return () => clearInterval(fetchStatusIntervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='form w-100 text-center'>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='fw-bolder mb-5 text-uppercase text-white' style={{fontSize: 32}}>
          {trainee?.group?.name}
        </h1>
      </div>
      {/* begin::Heading */}

      <p className='fs-2 mb-20'>Sikeres csatlakozás!</p>

      <div className='trainee-sidebar__info-block mb-20'>
        {trainee?.group?.status !== 'active' ? (
            <>
            A csoport még nem nyílt meg!
            <br />
            Kérjük várj, amíg az oktató megnyitja a csoportot!
            </>
        ) : (
            <>
            A csoport indítása folyamatban...
            </>
        )}
        
      </div>

      {/* begin::Action */}
      <div className='d-grid mb-20'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-esl-red btn-shadow btn-secondary'
          disabled={loading}
        >
          {!loading && <span className='indicator-label'>Vissza a csoport csatlakozáshoz</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Kérem várjon...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </div>
  )
}
