/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { isBusinessAdmin, isSbcAdmin, KTSVG } from '../../../../helpers'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { currentUser } = useAuth()

  return (
    <>
      <SidebarMenuItem
        to='/products'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({ id: 'MENU.PRODUCTS' })}
        fontIcon='bi-layers'
      />

    </>
  )
}

export { SidebarMenuMain }
